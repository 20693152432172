<template>
  <div class="wrapper">
    <LoginModal v-if="showLoginModal" :closeModal="toggleLoginModal" />
    <header class="onboarding-header">
      <span>Already have an account?</span>
      <router-link :to="{ name: 'login' }" tag="button">
        Sign in
      </router-link>
    </header>
    <div class="content">
      <div class="text-container">
        <h3>
          {{ title }}
        </h3>
        <div class="description-container">
          <span v-for="(text, index) of descriptions" :key="text">
            <p :class="{ 'inline-para': index === descriptions.length - 1 }">
              {{ text }}
            </p>
            <a v-if="index === descriptions.length - 1" :href="`tel:${contactNumber}`">
              {{ displayContactNumber }}
            </a>
          </span>
        </div>
      </div>
      <div class="loader-container" v-if="loading">
        <div>
          <Spinner :size="64" :loader="true" color />
        </div>
      </div>
      <div v-else>
        <FindCompany v-if="tenantName !== 'canada'" @input="emittedValues => handleCompanySearch(emittedValues)" />
        <DynamicForm
          isNewOnboardingJourney
          @previous-click="() => handlePreviousClick()"
          @submit="event => handleSubmit(event)"
          v-if="formFields"
          :form="formFields"
          :loading="loading"
          :submitState="{ loading: isSubmitting, submitLoadingPercentage }"
          :submitButtonText="isSubmitting ? 'One moment...' : 'Create account'"
          enableAgreeText
        />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import LoginModal from '@/features/onboarding/components/LoginModal.vue'
import DynamicForm from '@/components/dynamicForms/DynamicForm.vue'
import FindCompany from '@/features/onboarding/components/FindCompany.vue'
import Spinner from '@/components/Spinner'
import onboardingConfig from '@/features/onboarding/config'
import deepLinking from '@/mixins/deepLinking'
import amplitudeTracking from '@/mixins/amplitudeTracking'
import { mapGetters, mapActions } from 'vuex'
import { formatEnteredCompanyData } from '@/features/onboarding/formatPayloads'
import { tenantGetters } from '@/store/modules/tenant/routines'
import { authGetters, groupRoutine, loginRoutine } from '@/store/modules/auth/routines'
import { register } from '@/api/user'
import { passwordGenerator } from '@/utils/passwordGenerator'
import { getOnboardingSection } from '@/api/goals'
import { toastDefaultOptions } from '@/config/vue-toast'
import { getCookie, getGclid, getSubdomain, getURLSubdomain, sendBasicEventToAmplitude, setTenantNameCookie } from '@/utils/utils'
import { formatName } from '../onboarding/helpers'
import { amplitudeEvents, swoopPartnerTypeCookieName, defaultGroupId } from '@/utils/constants'
import { getGroupInformation } from '@/api/group/index'
import VueMultianalytics, { vueMultianalyticsConf } from '@/config/vue-multianalytics.config'
import { saveStageGoal } from '@/store/modules/onboarding/routines'
import { SET_ACTIVE_STEP_INDEX, stepCounterGetters } from '@/store/modules/goalsStepCounter/routines'

export default {
  name: 'signUpUserForm',
  mixins: [amplitudeTracking, deepLinking],
  components: { DynamicForm, FindCompany, Spinner, LoginModal },
  data() {
    return {
      areCompanySearchQuestionsShown: false,
      lastEnteredCompanyName: null,
      dynamicCompanyFields: null,
      findCompanyData: null,
      fundingGoals: null,
      formFields: onboardingConfig.signupUser.form.formFields,
      sectionId: null,
      loading: null,
      isSubmitting: false,
      submitLoadingPercentage: 0,
      showLoginModal: false
    }
  },
  computed: {
    ...mapGetters({
      tenantName: tenantGetters.TENANT_NAME,
      user: authGetters.USER,
      isInvitedUser: authGetters.IS_INVITED_USER,
      invitedUserSource: authGetters.INVITED_USER_SOURCE,
      stepCounterList: stepCounterGetters.STEP_COUNTER_LIST
    }),
    descriptions() {
      return onboardingConfig.signupUser.description
    },
    displayContactNumber() {
      return this.$config.dictionary.contactNumber
    },
    contactNumber() {
      return this.$config.dictionary.contactNumber.split(' ').join('')
    },
    title() {
      return onboardingConfig.signupUser.title
    }
  },
  methods: {
    ...mapActions({
      login: loginRoutine.TRIGGER,
      saveStageGoal: saveStageGoal.TRIGGER,
      setActiveIndex: SET_ACTIVE_STEP_INDEX,
      setGroup: groupRoutine.TRIGGER
    }),
    clearFieldValues() {
      onboardingConfig.signupUser.form.formFields.forEach(field => (field.value = null))
    },
    alertHandler(emailAddress) {
      if (!emailAddress) return
      const env = window.location.href.split('.')[1]
      if (env === 'stage' || env === 'dev' || env === 'demo') return
      const email = emailAddress.toLowerCase()
      if (email.includes('swoopfunding.com') || email.includes('+')) {
        if (window.confirm('Did you mean to use the swoop app DEMO?\n\nClick OK to go to the DEMO site or cancel to ignore.')) {
          window.location.href = 'https://app.demo.swoopfunding.com/'
        }
      }
    },
    trackFormSubmitEvents(email, isEmailMarketing) {
      const referralSource = window.WHITE_LABEL_STYLES?.subdomain === 'swoop' ? null : window.WHITE_LABEL_STYLES.subdomain
      // Tracking Amplitude Analytics event.
      this.$ma.setUserProperties({
        'Account type': 'business',
        'Referral source': referralSource,
        'UTM source': this.utm.medium === 'referral' && this.utm.source ? this.utm.source : '',
        'UTM campaign': this.utm?.campaign ? this.utm.campaign : '',
        'Email Marketing Box Ticked': isEmailMarketing,
        email
      })

      this.$ma.trackEvent({
        eventType: amplitudeEvents.fundingOnboarding.FORM_SUBMITTED,
        eventProperties: {
          'Referral source': referralSource,
          'UTM source': this.utm.medium === 'referral' && this.utm.source ? this.utm.source : ''
        }
      })
    },
    handleSubmit(values) {
      if (process.env.NODE_ENV === 'production') this.alertHandler(values.email)
      if (this.stepCounterList.length) this.setActiveIndex(this.stepCounterList.findIndex(item => item === 'Sign up') + 1 || 0)

      let enteredCompanyDetails = null
      if (this.areCompanySearchQuestionsShown) {
        enteredCompanyDetails = formatEnteredCompanyData(values)
      }
      this.utm = this.filterParameters({ ...this.getUtmParameters(), ...this.getURLParams() })

      const finalFormData = {
        type: 'user',
        email: values.email.trim(),
        contactNumber: values.contactNumber,
        company: {
          ...(enteredCompanyDetails ? { ...enteredCompanyDetails } : { ...this.findCompanyData, name: this.findCompanyData.companyName })
        },
        password: values.password || passwordGenerator(),
        firstName: formatName(values.firstName),
        lastName: formatName(values.lastName),
        isEmailMarketing: values.isEmailMarketing || false,
        origin: window.WHITE_LABEL_STYLES?.subdomain !== 'swoop' ? getSubdomain() : getCookie('utm_source') || 'organic',
        originType: getCookie(swoopPartnerTypeCookieName) || null,
        gclid: getGclid(),
        tenant: this.tenantName,
        isWhitelabel: window.WHITE_LABEL_STYLES?.subdomain !== 'swoop',
        utm: Object.keys(this.utm).length ? this.utm : null,
        incorporationCountry: this.$config.whitelabel.country.defaultCountry,
        incorporationRegion: null,
        fromInvite: this.isInvitedUser,
        inviteSource: this.invitedUserSource,
        isBackEntrance: true
      }

      if (finalFormData.company?.postcode) finalFormData.postcode = finalFormData.company.postcode

      return this.registerUser(finalFormData)
    },
    async registerUser(finalFormData) {
      this.isSubmitting = true
      this.submitLoadingPercentage = 20
      this.checkTestUser(finalFormData.email)

      // Register and show login modal if the registration fails
      const registrationSuccess = await register(finalFormData).then(
        response => {
          this.trackSignUpEvents(finalFormData.email)
          setTenantNameCookie(getURLSubdomain())

          if (!this.isExternalGroup) {
            this.$ma.identify({
              userId: response.data.userId
            })

            this.trackFormSubmitEvents(finalFormData.email, finalFormData.isEmailMarketing)
            sendBasicEventToAmplitude(this.$ma, amplitudeEvents.businessOnboarding.CREATE_USER)
          }

          localStorage.setItem('accountCreated', response.data.userId)
          return true
        },
        error => {
          // restricts the login modal to only show on failed registration
          console.error(error)
          this.showLoginModal = true
          this.resetLoadingValues()
          sendBasicEventToAmplitude(this.$ma, amplitudeEvents.businessOnboarding.ALREADY_SIGNUP)
          return false
        }
      )

      // Login user if registration is successful
      if (registrationSuccess) {
        this.submitLoadingPercentage = 80
        this.login({
          userName: finalFormData.email,
          password: finalFormData.password
        }).then(
          response => {
            this.resetLoadingValues()
            this.handleDeepLinkRedirect(this.isInvitedUser ? 'integrations' : 'dashboard')
          },
          error => {
            this.resetLoadingValues()
            this.showError(error.response.data.message)
          }
        )
      }
    },
    async fetchDynamicFields() {
      try {
        const { data } = await getOnboardingSection()
        this.sectionId = data.formSection.formSectionId
        if (data.formSection.formFields) {
          // handles adding of dynamic fields into this.formFields
          this.dynamicCompanyFields = data.formSection.formFields.filter(field => onboardingConfig.signupUser.companySearchRelatedFieldNames.includes(field.name))
        }
      } catch (error) {
        this.$toasted.show('Something went wrong.', { ...toastDefaultOptions, type: 'error', icon: 'error' })
      }
    },
    handleCompanySearch(values) {
      if (values.companyName) this.lastEnteredCompanyName = values.companyName
      if (values.isCantFindCompany !== this.areCompanySearchQuestionsShown) {
        this.toggleCompanySearchRelatedQuestions()
      }
      this.findCompanyData = values
    },
    toggleCompanySearchRelatedQuestions() {
      this.areCompanySearchQuestionsShown = !this.areCompanySearchQuestionsShown
      if (this.areCompanySearchQuestionsShown) {
        const companyNameQuestions = onboardingConfig.signupUser.form.companyNameQuestions
        companyNameQuestions[0].value = this.lastEnteredCompanyName
        const newFields = [].concat([...companyNameQuestions, ...onboardingConfig.signupUser.staticQuestions], this.dynamicCompanyFields)
        this.formFields = [].concat(newFields, this.formFields)
      } else {
        this.formFields = this.formFields.filter(field => !onboardingConfig.signupUser.companySearchRelatedFieldNames.includes(field.name))
      }
    },
    disableGoogleAnalytics() {
      const googleAnalyticsScript = document.getElementById('google-analytics-script')

      if (googleAnalyticsScript) googleAnalyticsScript.parentNode.removeChild(googleAnalyticsScript)
    },
    checkTestUser(email) {
      if (email.endsWith('@swoopfunding.com')) {
        this.disableGoogleAnalytics()
        vueMultianalyticsConf.modules.amplitude = {
          ...vueMultianalyticsConf.modules.amplitude,
          apiKey: ''
        }
        Vue.use(VueMultianalytics, vueMultianalyticsConf)
      }
    },
    showError(message = 'User creation failed, please try again') {
      this.$toasted.clear()

      this.$toasted.show(`${message}`, { ...toastDefaultOptions, type: 'error', icon: 'error', singleton: true })
    },
    toggleLoginModal() {
      this.showLoginModal = !this.showLoginModal
    },
    resetLoadingValues() {
      this.isSubmitting = false
      this.submitLoadingPercentage = 0
      this.loading = false
    }
  },
  async mounted() {
    this.loading = true
    await this.fetchDynamicFields()

    this.saveStageGoal(null)
    this.clearFieldValues()
    if (this.tenantName === 'canada') this.toggleCompanySearchRelatedQuestions()
    if (this.stepCounterList.length) this.setActiveIndex(this.stepCounterList.findIndex(item => item === 'Sign up') || 0)
    if (this.$route.query.groupId) {
      getGroupInformation(this.$route.query.groupId).then(({ data }) => {
        data.fromInvite = true
        data.inviteSource = window.location.href
        data.isExternalGroup = this.$route.query.groupId !== defaultGroupId
        this.setGroup(data)
      })
    }
    this.trackLloydsGrantFinderEvent(amplitudeEvents.pageViews.LLOYDS_BANK_GRANT_FINDER, 'Sign-up')
    this.$ma.trackEvent(amplitudeEvents.signUp.SME)

    this.resetLoadingValues()
  },
  unmounted() {
    this.resetLoadingValues()
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/styles/swoop/_variables.scss';
.wrapper {
  min-height: 100%;
  min-width: 100%;
  background-color: #f9fafb !important;
}
.onboarding-header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 16px;
  background-color: #f9fafb;
  span {
    margin-right: 10px;
    font-size: 14px;
    font-weight: 500;
    color: $color-primary;
    text-decoration: none;
  }
  button {
    font-family: 'Public Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    width: 80px;
    height: 32px;
    box-sizing: border-box;
    border: 1px solid var(--color-secondary-100);
    border-radius: 10px;
    background-color: transparent;
    color: $color-general-headers;
    &:hover {
      cursor: pointer;
      color: white;
      background-color: var(--color-primary-400);
    }
  }
}
.text-container {
  padding: 0 50px;
  margin-bottom: 48px;
  h3 {
    font-size: 28px;
  }
  .description-container {
    p {
      margin: 0;
      padding: 0;
      font-size: 16px;
    }
    .inline-para {
      display: inline;
      word-wrap: break-word;
    }
    a {
      font-size: 16px;
    }
  }
}
.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  padding: 24px;
  max-width: 1200px;
  .loader-container {
    display: flex;
    justify-self: center;
    align-self: center;
  }
}
@media only screen and (max-width: 600px) {
  .text-container {
    padding: 0 16px;
    margin-bottom: 16px;
  }
  .content {
    padding: 8px;
  }
}
@media only screen and (max-width: 830px) {
  .text-container {
    margin-top: 24px;
    padding: 0 12px;
    margin-bottom: 24px;
  }
}
</style>
